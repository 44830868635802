<template>
    <v-popover
        trigger="hover"
        placement="left"
        :offset="-0.5"
        :autoHide="false"
    >
        <slot></slot>
        <div slot="popover" style="padding: 15px;">
            <div class="info-popover">
                <img :src="data.image_zoom_out || defaultAvatar" class="info-popover__avatar">
                <div class="info-popover__wrap">
                    <div class="info-popover__header">
                        <a
                           class="info-popover__name"
                           :href="`/auth/profile/${data.id}/`"
                           target="_blank"
                        >
                            {{data.full_name}}
                        </a>

                        <router-link
                            class="info-popover__edit"
                            tag="a"
                            :to="`/auth/profile/edit/${data.id}`"
                            v-tooltip="{
                                content: 'Редактировать профиль',
                                offset: 7
                            }"
                        >
                            <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.49844 2.29171L9.95146 4.74471L3.74218 10.954L1.29054 8.50099L7.49844 2.29171ZM11.8128 1.7001L10.7189 0.606144C10.2961 0.183369 9.60961 0.183369 9.18539 0.606144L8.13749 1.65404L10.5905 4.10707L11.8128 2.88475C12.1407 2.55682 12.1407 2.02801 11.8128 1.7001ZM0.00682619 11.9489C-0.0378157 12.1498 0.143579 12.3298 0.344513 12.281L3.078 11.6182L0.626364 9.1652L0.00682619 11.9489Z"/>
                            </svg>
                        </router-link>
                    </div>

                    <div class="info-popover__descr">
                        <div class="info-popover__position" v-if="data.positions"><span class="info-popover__title">Должность:</span> {{data.positions.name || null}}</div>
                        <div class="info-popover__struct" v-if="data.structure"><span class="info-popover__title">Подразделение:</span> {{data.structure[0].name || null }}</div>
                        <div class="info-popover__email"><span class="info-popover__title">Email:</span> <a :href="`mailto:${data.email}`" class="js-employer-email">{{data.email}}</a>
                        </div>
                        <div class="info-popover__phone" v-if="data.phone_number"><span class="info-popover__title">Телефон:</span> {{data.phone_number}}</div>
                    </div>
                </div>
            </div>
        </div>
    </v-popover>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "InfoPopover",
        data() {
            return {
                defaultAvatar
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-popover {
        display: flex;
        align-items: center;
        &__avatar {
            max-width: 100px;
            max-height: 100px;
            border-radius: 50%;
        }
        &__wrap {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-left: 20px;
        }
        &__header {
            gap: 5px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__edit {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 22px;
            height: 22px;
            border-radius: 100px;
            background-color: #f0f2f5;
            svg {
                width: 12px;
                height: 12px;
                fill: #c3c7d6;
            }
        }
        &__descr {
            font-size: 0.875rem;
        }
        &__title {
            font-weight: 500;
        }
        &__name {
            flex-grow: 1;
        }
    }
</style>
